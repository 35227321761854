import React from "react";
import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivateRouter } from "../helper/PrivateRouter";
import CreateDataset2 from "../pages/users/CreateDataset2";

const Login = lazy(() => import("../pages/auth/Login"));
const Dashboard = lazy(() => import("../pages/users/Dashboard"));
const Dataset = lazy(() => import("../pages/users/Dataset"));
const CreateDataset = lazy(() => import("../pages/users/CreateDataset"));
const CreateDatasetMain = lazy(() =>
  import("../pages/users/CreateDatasetMain")
);
const Signup = lazy(() => import("../pages/auth/Signup"));
const Forgot = lazy(() => import("../pages/auth/Forgot"));
const NewPassword = lazy(() => import("../pages/auth/NewPassword"));
const DataWrangling = lazy(() => import("../pages/users/DataWrangling"));
const LinkedInCallback = lazy(() => import("react-linkedin-login-oauth2"));
const Page404 = lazy(() => import("../pages/Page404"));

const Routing = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route exact path='/' element={<Login />} />
          <Route
            exact
            path='/Dashboard'
            element={
              <PrivateRouter>
                <Dashboard />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path='/Dataset'
            element={
              <PrivateRouter>
                <Dataset />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path='/CreateDataset'
            element={
              <PrivateRouter>
                <CreateDataset />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path='/CreateDataset2'
            element={
              <PrivateRouter>
                <CreateDataset2 />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path='/CreateDatasetMain'
            element={
              <PrivateRouter>
                <CreateDatasetMain />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path='/DataWrangling'
            element={
              <PrivateRouter>
                <DataWrangling />
              </PrivateRouter>
            }
          />
          <Route exact path='/Signup' element={<Signup />} />
          <Route exact path='/Forgot' element={<Forgot />} />
          <Route exact path='/NewPassword' element={<NewPassword />} />
          <Route exact path='/linkedin' element={<LinkedInCallback />} />
          <Route path='/404Page' element={<Page404 />} />
          <Route exact path='*' element={<Page404 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export default Routing;
