import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../../assets/img/logo.png";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import StorageRoundedIcon from "@mui/icons-material/StorageRounded";
import PieChartRoundedIcon from "@mui/icons-material/PieChartRounded";
import { useNavigate, useLocation } from "react-router-dom";

const drawerWidth = 240;
const closedrawerWidth = 80;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // width: `calc(${theme.spacing(7)} + 1px)`,
  width: closedrawerWidth,
  [theme.breakpoints.up("sm")]: {
    // width: `calc(${theme.spacing(8)} + 1px)`,
    width: closedrawerWidth,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  [theme.breakpoints.down("md")]: {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  },
}));

export default function MiniDrawer({ open, setDrawerOpen }) {
  const theme = useTheme();
  // const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  let pathname = location.pathname;

  const handleDrawerOpen = () => {
    // setOpen(!open);
    setDrawerOpen();
  };

  return (
    <Drawer variant='permanent' open={open}>
      <div class='logo'>
        <IconButton
          sx={{
            minWidth: "34px",
            marginRight: ".9rem",
            marginLeft: ".9rem",
            border: "none",
            background: "none",
            padding: "0",
            display: "block",
          }}
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerOpen}
          edge='start'
        >
          <MenuIcon />
        </IconButton>
        <img src={Logo} class={open ? "logoOpen" : "logoClose"}></img>
      </div>

      <List class='sidebar-menu'>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={pathname.includes("Dashboard") ? true : false}
            onClick={() => navigate("/Dashboard")}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              mx: 0,
              borderRadius: "8px",
              "&.Mui-selected": {
                backgroundColor: "#2196F3",
                color: "#fff",
                "& svg": {
                  fill: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#2196F3",
                },
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                lineHeight: "0",
              }}
            >
              <DashboardRoundedIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
              Dashboard
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={pathname.includes("Dataset") ? true : false}
            onClick={() => navigate("/Dataset")}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              mx: 0,
              borderRadius: "8px",
              "&.Mui-selected": {
                backgroundColor: "#2196F3",
                color: "#fff",
                "& svg": {
                  fill: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#2196F3",
                },
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                lineHeight: "0",
              }}
            >
              <StorageRoundedIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }}>Dataset</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={pathname.includes("Datawrangling") ? true : false}
            onClick={() => navigate("/Datawrangling")}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              mx: 0,
              borderRadius: "8px",
              "&.Mui-selected": {
                backgroundColor: "#2196F3",
                color: "#fff",
                "& svg": {
                  fill: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#2196F3",
                },
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                lineHeight: "0",
              }}
            >
              <StorageRoundedIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
              Datawrangling
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={pathname.includes("DataAnalytics") ? true : false}
            onClick={() => navigate("/DataAnalytics")}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              mx: 0,
              borderRadius: "8px",
              "&.Mui-selected": {
                backgroundColor: "#2196F3",
                color: "#fff",
                "& svg": {
                  fill: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#2196F3",
                },
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                lineHeight: "0",
              }}
            >
              <PieChartRoundedIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
              Data Analytics
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
