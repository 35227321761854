import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { rootReducer } from "../reducers/index";
import persistStore from "redux-persist/es/persistStore";
import { persistReducer } from "redux-persist";

const composeEnhancers = composeWithDevTools({});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { store, persistor };
