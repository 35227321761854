import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "../../components/layouts/SideBar";
import DrawerHeader from "../../components/layouts/Header";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Amazon from "../../assets/img/amazon.png";

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: "Fern",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
  },
  {
    img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
    title: "Bike",
  },
];

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);

  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer open={open} setDrawerOpen={() => handleDrawerOpen()} />
      <Box component='main' class={open ? "" : "rightContentClosed"}>
        <DrawerHeader />
        <div class='dataset-main'>
          <Box sx={{ width: "calc(100% - 280px)", minHeight: "100%" }}>
            <Card
              sx={{
                height: "500px",
                overflowY: "auto",
                scrollbarWidth: "thin",
                boxShadow: "none",
                borderRadius: "0",
              }}
            >
              <CardMedia component='img' image={Amazon} />
            </Card>
            <Stack
              direction='row'
              spacing={2}
              sx={{
                margin: "2rem 0",
                "& button": {
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  fontWeight: "400",
                },
              }}
            >
              <Button variant='contained' color='success' disableElevation>
                Excel/CSV
              </Button>
              <Button variant='outlined' color='primary'>
                Json
              </Button>
              <Button variant='outlined' color='primary'>
                XML
              </Button>
            </Stack>
            <Card
              sx={{ boxShadow: "none", borderRadius: "0", padding: "1rem" }}
            >
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Website</TableCell>
                    <TableCell align='left'>Brand</TableCell>
                    <TableCell align='center'>Star Rating</TableCell>
                    <TableCell align='center'>Price</TableCell>
                    <TableCell align='left'>Link</TableCell>
                    <TableCell align='center'>Main Source</TableCell>
                    <TableCell align='left'>Company Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align='left'>AMAZON</TableCell>
                    <TableCell align='left'> Samsung</TableCell>
                    <TableCell align='center'>4.8 out of 5.0</TableCell>
                    <TableCell align='center'>$108</TableCell>
                    <TableCell align='left'> 4 days ago</TableCell>
                    <TableCell align='center'>
                      <Button
                        href='#text-buttons'
                        disableRipple
                        sx={{
                          p: 0,
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        View Details
                      </Button>
                    </TableCell>
                    <TableCell align='left'> CRAIGSLIST</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left'>AMAZON</TableCell>
                    <TableCell align='left'> Samsung</TableCell>
                    <TableCell align='center'>4.8 out of 5.0</TableCell>
                    <TableCell align='center'>$108</TableCell>
                    <TableCell align='left'> 4 days ago</TableCell>
                    <TableCell align='center'>
                      <Button
                        href='#text-buttons'
                        disableRipple
                        sx={{
                          p: 0,
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        View Details
                      </Button>
                    </TableCell>
                    <TableCell align='left'> CRAIGSLIST</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left'>AMAZON</TableCell>
                    <TableCell align='left'> Samsung</TableCell>
                    <TableCell align='center'>4.8 out of 5.0</TableCell>
                    <TableCell align='center'>$108</TableCell>
                    <TableCell align='left'> 4 days ago</TableCell>
                    <TableCell align='center'>
                      <Button
                        href='#text-buttons'
                        disableRipple
                        sx={{
                          p: 0,
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        View Details
                      </Button>
                    </TableCell>
                    <TableCell align='left'> CRAIGSLIST</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left'>AMAZON</TableCell>
                    <TableCell align='left'> Samsung</TableCell>
                    <TableCell align='center'>4.8 out of 5.0</TableCell>
                    <TableCell align='center'>$108</TableCell>
                    <TableCell align='left'> 4 days ago</TableCell>
                    <TableCell align='center'>
                      <Button
                        href='#text-buttons'
                        disableRipple
                        sx={{
                          p: 0,
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        View Details
                      </Button>
                    </TableCell>
                    <TableCell align='left'> CRAIGSLIST</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Box>
          <Box
            sx={{
              width: "280px",
              minHeight: "100%",
              padding: "1rem",
              background: "#fff",
            }}
          >
            <Typography
              sx={{
                color: "#707070",
                fontSize: "1.5rem",
                fontWeight: "400",
                lineHeight: "1",
                marginBottom: "24px",
              }}
            >
              32 Filters found
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "1",
                marginBottom: "16px",
              }}
            >
              Capture List
            </Typography>
            <List
              sx={{
                p: 0,
                marginBottom: "1.5rem",
                "& li": {
                  width: "auto",
                  padding: "0",
                  margin: "0 0.5rem 1rem 0",
                  "& .MuiCheckbox-root": {
                    padding: "0 9px",
                  },
                  "& .MuiFormControlLabel-label": {
                    marginTop: "0",
                    fontSize: "13px",
                    marginRight: "0",
                    color: "#263238",
                  },
                  "& .MuiSvgIcon-fontSizeMedium": {
                    fontSize: "1.25rem",
                  },
                },
              }}
            >
              <ListItem>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ alignItems: "flex-start" }}
                  label='Echo Dot (3Rd Gen)'
                />
              </ListItem>
              <ListItem>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ alignItems: "flex-start" }}
                  label='Brand: Amazon'
                />
              </ListItem>
              <ListItem>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ alignItems: "flex-start" }}
                  label='M.R.P. ₹4,499.00'
                />
              </ListItem>
              <ListItem>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ alignItems: "flex-start" }}
                  label='Deal of the Day: ₹1,749.00'
                />
              </ListItem>
              <ListItem>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ alignItems: "flex-start" }}
                  label='10 days returnable'
                />
              </ListItem>
              <ListItem>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ alignItems: "flex-start" }}
                  label='4.5 star rating'
                />
              </ListItem>
            </List>
            <Button
              variant='outlined'
              color='primary'
              sx={{
                textTransform: "capitalize",
                fontWeight: "400",
                borderRadius: "8px",
                marginBottom: "2rem",
              }}
            >
              + Capture Missing details
            </Button>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "1",
                marginBottom: "16px",
              }}
            >
              Capture Pair Value
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#263238", my: 2 }}>
              - No data captured
            </Typography>
            <Button
              variant='outlined'
              color='primary'
              sx={{
                textTransform: "capitalize",
                fontWeight: "400",
                borderRadius: "8px",
                marginBottom: "2rem",
              }}
            >
              + Capture Missing Pair Value
            </Button>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "1",
                marginBottom: "16px",
              }}
            >
              Capture Image
            </Typography>
            <ImageList sx={{ display: "flex", flexWrap: "wrap", gap: "0" }}>
              {itemData.map((item) => (
                <ImageListItem
                  key={item.img}
                  sx={{
                    width: "50px",
                    minHeight: "50px",
                    display: "inline-block",
                    border: "2px solid #D5DBDB",
                    margin: "0 .5rem .5rem 0",
                    overflow: "hidden",
                    "& img": { width: "50px", height: "50px" },
                  }}
                >
                  <img
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading='lazy'
                  />
                </ImageListItem>
              ))}
            </ImageList>
            <Button
              variant='outlined'
              color='primary'
              sx={{
                textTransform: "capitalize",
                fontWeight: "400",
                borderRadius: "8px",
                marginBottom: "2rem",
              }}
            >
              + Capture Missing Images
            </Button>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "1",
                marginBottom: "16px",
              }}
            >
              Scroll Page
            </Typography>
            <List
              sx={{
                p: 0,
                marginBottom: "1.5rem",
                "& li": {
                  width: "auto",
                  padding: "0",
                  margin: "0 0.5rem 1rem 0",
                  "& .MuiCheckbox-root": {
                    padding: "0 9px",
                  },
                  "& .MuiFormControlLabel-label": {
                    marginTop: "0",
                    fontSize: "13px",
                    marginRight: "0",
                    color: "#263238",
                  },
                  "& .MuiSvgIcon-fontSizeMedium": {
                    fontSize: "1.25rem",
                  },
                },
              }}
            >
              <ListItem>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ alignItems: "flex-start" }}
                  label='All Pages'
                />
              </ListItem>
              <ListItem>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ alignItems: "flex-start" }}
                  label='1-5 pages'
                />
              </ListItem>
              <ListItem>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ alignItems: "flex-start" }}
                  label='6-10 pages'
                />
              </ListItem>
              <ListItem>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ alignItems: "flex-start" }}
                  label='11-15 pages'
                />
              </ListItem>
              <ListItem>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ alignItems: "flex-start" }}
                  label='16-20 pages'
                />
              </ListItem>
            </List>

            <Stack
              direction='row'
              spacing={2}
              sx={{
                "& button": {
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  fontWeight: "400",
                },
              }}
            >
              <Button variant='outlined' color='primary'>
                Apply
              </Button>
              <Button variant='outlined' color='error'>
                Reset
              </Button>
            </Stack>
            <Button
              variant='contained'
              fullWidth
              color='primary'
              size='large'
              disableElevation
              sx={{
                textTransform: "capitalize",
                fontWeight: "400",
                borderRadius: "8px",
                margin: "1rem 0",
              }}
            >
              Run
            </Button>

            {/* <Accordion expanded={expanded === 'panel1'} sx={{boxShadow: 'none', '&::before': {backgroundColor: 'transparent'}}} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"           
        >  
          <Typography  sx={{ color: 'text.secondary', }}>Show Advanced Mode</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
            Aliquam eget maximus est, id dignissim quam.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
          </Box>
        </div>
      </Box>
    </Box>
  );
}
