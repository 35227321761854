import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";

export const PrivateRouter = ({ children }) => {
  const location = useLocation();
  const auth = useAuth();

  if (!auth.authToken) {
    return <Navigate to='/' state={{ path: location.pathname }} />;
  }
  return children;
};
