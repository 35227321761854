import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardDetails: {
    completed: [],
    failed: [],
    running: [],
    scheduled: [],
  },
  isSelectedStatus: 0,
};

const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState,
  reducers: {
    setDashboardDetails: (state, action) => {
      return {
        ...state,
        dashboardDetails: action.payload,
      };
    },
    setSelectedStatus: (state, action) => {
      return {
        ...state,
        isSelectedStatus: action.payload,
      };
    },
  },
});

export const { setDashboardDetails, setSelectedStatus } =
  DashboardSlice.actions;
export default DashboardSlice.reducer;
