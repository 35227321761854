import React, { createContext, useContext, useState, useMemo } from "react";
import Cookies from "universal-cookie";

const AuthContext = createContext();
const cookies = new Cookies();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(cookies.get("authToken") || null);

  const logIn = (token) => {
    cookies.set("authToken", { login: token });
    setAuthToken(token);
  };

  const logOut = () => {
    cookies.remove("authToken");
    setAuthToken(null);
  };

  const values = useMemo(
    () => ({
      authToken,
      logIn,
      logOut,
    }),
    [authToken, logIn, logOut]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
export default AuthProvider;
