import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Profile from "../../assets/img/profile.jpg";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { GoogleLogout } from "react-google-login";
import { AUTH_TOKEN } from "../../constants";
import Cookies from "universal-cookie";
import { gapi } from "gapi-script";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../../context/AuthProvider";
const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  opacity: "1",
}));

const createData = {
  marginRight: "1rem",
  background: "#2196F3",
  borderRadius: 8,
  border: 0,
  boxShadow: "none",
  textTransform: "capitalize",
  fontWeight: "400",
};

export default function Header() {
  const theme = useTheme();
  const { userDetails } = useSelector((state) => state.user);
  const { profilepic } = userDetails[0];
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const signOut = () => {
    if (gapi) {
      const auth2 = gapi.auth2.getAuthInstance();
      console.log("auth2", auth2);
      if (auth2 != null) {
        auth2.signOut().then(auth2.disconnect());
      }
    }
    gapi.load("auth2", function () {
      var auth2 = gapi.auth2.init({
        client_id: REACT_APP_GOOGLE_CLIENT_ID,
        cookiepolicy: "single_host_origin",
      });
      auth2.then(function () {
        // this get called right after token manager is started
        auth2.signOut();
        console.log("User signed out.");
      });
    });
    auth.logOut();
    navigate("/");
  };

  return (
    <DrawerHeader
      sx={{
        display: "flex",
        justifyContent: "space-between",
        minHeight: "auto",
        padding: "0",
        [theme.breakpoints.up("sm")]: {
          minHeight: "auto",
        },
      }}
    >
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography
          color='text.primary'
          sx={{ fontSize: "24px", color: "#263238", fontWeight: "400" }}
        >
          Dashboard
        </Typography>
      </Breadcrumbs>

      <Box sx={{ display: "flex" }}>
        {
          <GoogleLogout
            clientId={REACT_APP_GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <div className='logoutbutton'>
                <Button
                  onClick={renderProps.onClick}
                  variant='outlined'
                  disableElevation
                >
                  Logout
                </Button>
              </div>
            )}
            onLogoutSuccess={signOut}
          />
        }
        <Button
          variant='contained'
          color='primary'
          style={createData}
          onClick={() => navigate("/CreateDatasetMain")}
        >
          Create Dataset
        </Button>

        {/* <Avatar alt='Cindy Baker' src={ imageUrl || Profile } /> */}
        <Avatar alt='Cindy Baker' src={profilepic} />
      </Box>
    </DrawerHeader>
  );
}
