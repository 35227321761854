import { combineReducers } from "redux";
import UserSlice from "./userReducer";
import DashboardSlice from "./dashboardReducer";
import AuthSlice from "./authReducer";

export const rootReducer = combineReducers({
  user: UserSlice,
  dashboardDetails: DashboardSlice,
  // auth: AuthSlice,
});
