import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
};

const UserSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      return {
        ...state,
        userDetails: action.payload,
      };
    },
  },
});

export const { setUserDetails } = UserSlice.actions;
export default UserSlice.reducer;
